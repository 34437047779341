export const ORIGINS = {
  ANIM: "Animation",
  BIO: "Bio",
  MSC: "MSC",
  LOC: "Produit local",
  MAI: "Préparation maison",
  PFR: "Produit français",
  REG: "Recette régionale",
  TFR: "Terroir de France",
  VEG: "Recette végétarienne",
  FEHAP: "Menu Fehap"
};

export const PRODUCT_TYPES = {
  STARTER: "ENT",
  MAIN: "PLA",
  DESSERT: "DES",
  DRINK: "BOI",
  SIDE: "ACC",
  // FORMULA: "FOR",
  DAIRY: "LAI",
  CHEESE: "FRO",
  OTHER: "AUT"
};

export const PRODUCT_TYPES_NAMES = {
  [PRODUCT_TYPES.STARTER]: "Entrée",
  [PRODUCT_TYPES.MAIN]: "Plat",
  [PRODUCT_TYPES.DESSERT]: "Dessert",
  [PRODUCT_TYPES.CHEESE]: "Fromage",
  [PRODUCT_TYPES.DAIRY]: "Laitage",
  [PRODUCT_TYPES.DRINK]: "Boisson",
  [PRODUCT_TYPES.SIDE]: "Accompagnement",
  [PRODUCT_TYPES.OTHER]: "Autre"
  // [PRODUCT_TYPES.FORMULA]: "Spécial : prix formule"
};
